import React from "react";
import Marquee from "react-fast-marquee";
import { useSelector } from "react-redux";
import { getColorOpacityPercentage } from "../../../../../utils/controllers/colors";

function SidebarOneFAQ({ handleGenerateAnswer, responseDataLength }) {
  const kaFAQQuestions = useSelector((state) => state.app.kaFAQQuestions);

  const primaryColor = useSelector(
    (state) => state.flipcard.data.flipCard.theme.primaryColor
  );

  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);

  if (kaFAQQuestions?.length && responseDataLength === 0) {
    return (
      <div className="kaFaqSidebarOneFAQs">
        <div className="kaFaqSidebarOneFAQsGradient gradient-left"></div>
        <div className="kaFaqSidebarOneFAQsGradient gradient-right"></div>

        <Marquee speed={50}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {kaFAQQuestions?.map((data, index) => {
              return (
                <div
                  className="kaFaqSidebarOneCard"
                  style={{ backgroundColor: bgColorOpacity }}
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      handleGenerateAnswer(data);
                    }, 200);
                  }}
                >
                  {data?.title}
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>
    );
  }

  return <></>;
}

export default SidebarOneFAQ;
