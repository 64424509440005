import usePostMessage from "../../../../utils/controllers/usePostMessage";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../utils/components/Icon";
import { setIsRagCollapsed } from "../../../../redux/slices/appSlice";

const CollapseIcon = ({ responseData }) => {
  const { postMessage } = usePostMessage();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };
  const dispatch = useDispatch();

  const kaFAQQuestions = useSelector((state) => state.app.kaFAQQuestions);
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  const isInline = injectionStyle === "inline";

  const getIconName = () => {
    if (isInline) {
      return isRagCollapsed ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line";
    }
    return isRagCollapsed ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line";
  };

  const handleClick = () => {
    dispatch(setIsRagCollapsed(!isRagCollapsed));
    sendCollapseEvent();
  };

  if (responseData?.length > 0 || kaFAQQuestions?.length > 0) {
    return (
      <div className="kaCollapseIcon">
        <Icon name={getIconName()} onClick={handleClick} />
      </div>
    );
  }

  return <></>;
};

export default CollapseIcon;
